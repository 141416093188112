import React from 'react';
import './Footer.css'


const Footer = () => {


    return (
        <div className="footer">
            ©{new Date().getFullYear()} Nate Sheridan
            <br></br>
            n8.wtf ▶ n8s.pw ◀ autofoc.us
        </div>
    )
}

export default Footer
